import React from 'react';
import propTypes from 'prop-types';
import './header.scss';

function Header(props) {
  const { title, children } = props;

  return (
    <div className="header">
      <h1>{title}</h1>
      <div className="header__buttons">
        {children}
      </div>
    </div>
  );
}

Header.propTypes = {
  title: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
};

export default Header;
