import React from 'react';

const BookDetails = () => (
  <>
    <div className="form-group">
      <label htmlFor="weight">
        <span>Weight (KG)</span>
        <input type="number" id="weight" name="weight" required />
      </label>
    </div>
    <p className="details">Please provide Book weight in Kilograms</p>
  </>
);

export default BookDetails;
