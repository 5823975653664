import React from 'react';

const FurnitureDetails = () => (
  <>
    <div className="form-group">
      <label htmlFor="height">
        <span>Height (CM)</span>
        <input type="number" id="height" name="height" required />
      </label>
    </div>
    <div className="form-group">
      <label htmlFor="width">
        <span>Width (CM)</span>
        <input type="number" id="width" name="width" required />
      </label>
    </div>
    <div className="form-group">
      <label htmlFor="length">
        <span>Length (CM)</span>
        <input type="number" id="length" name="length" required />
      </label>
    </div>
    <p className="details">Please provide dimensions in HxWxL format</p>
  </>
);

export default FurnitureDetails;
