import React from 'react';
import './footer.scss';

function Footer() {
  return (
    <footer>
      <p>Scandiweb Test assignment</p>
    </footer>
  );
}

export default Footer;
