import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import BookDetails from '../components/BookDetails';
import FurnitureDetails from '../components/FurnitureDetails';
import DvdDetails from '../components/DvdDetails';
import API_BASE_URL from '../api';
import './add-products-page.scss';

const AddProducts = () => {
  const [type, setType] = useState('Book');
  const navigate = useNavigate();

  const cancelHandler = () => {
    navigate('/');
  };

  const typeChangeHandler = (event) => {
    setType(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    data.type = type;
    fetch(`${API_BASE_URL}products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', // Required for CORS support to work
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Header title="Product Add">
        <button type="submit" form="product_form">Save</button>
        <button type="button" onClick={cancelHandler}>Cancel</button>
      </Header>
      <form id="product_form" method="post" onSubmit={submitHandler}>
        <div className="form-group">
          <label htmlFor="sku" id="sku-label">
            <span>SKU</span>
            <input type="text" id="sku" name="sku" required />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="name">
            <span>Name</span>
            <input type="text" id="name" name="name" required />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="price">
            <span>Price ($)</span>
            <input type="number" id="price" name="price" required />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="productType">
            <span>Product Switcher</span>
            <select id="productType" name="type" onChange={typeChangeHandler}>
              <option value="Book">Book</option>
              <option value="DVD">DVD</option>
              <option value="Furniture">Furniture</option>
            </select>
          </label>
        </div>
        <div className="product-details">
          {type === 'Book' && (<BookDetails />)}
          {type === 'Furniture' && (<FurnitureDetails />)}
          {type === 'DVD' && (<DvdDetails />)}
        </div>
      </form>
    </>
  );
};

export default AddProducts;
