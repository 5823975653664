import React from 'react';
import propTypes from 'prop-types';
import './product.scss';

const Product = (props) => {
  const {
    id, name, sku, price, size, weight, dimension,
  } = props;

  const handleChange = (event) => {
    const { id, checked } = event.target;
    const { items, setItems } = props;
    if (checked) {
      setItems([...items, id]);
    } else {
      setItems(items.filter((item) => item !== id));
    }
  };

  return (
    <div className="product">
      <p>{sku}</p>
      <p>{name}</p>
      <p>{price}</p>
      {size && (
      <p>
        Size:
        {size}
      </p>
      )}
      {weight && (
      <p>
        Weight:
        {weight}
      </p>
      )}
      {dimension && (
      <p>
        Dimension:
        {dimension}
      </p>
      )}
      <input type="checkbox" id={id} onChange={handleChange} className="delete-checkbox" />
    </div>
  );
};

Product.propTypes = {
  id: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  sku: propTypes.string.isRequired,
  price: propTypes.string.isRequired,
  size: propTypes.string,
  weight: propTypes.string,
  dimension: propTypes.string,
  items: propTypes.arrayOf(propTypes.string).isRequired,
  setItems: propTypes.func.isRequired,
};

Product.defaultProps = {
  size: null,
  weight: null,
  dimension: null,
};

export default Product;
