import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import API_BASE_URL from '../api';
import Product from './Product';
import './products.scss';

const Products = ({ items, setItems }) => {
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${API_BASE_URL}products`)
      .then((response) => {
        if (response.status >= 400) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setProducts(data.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [items]);

  if (error) {
    return (
      <div className="error">
        <h3>Something went wrong...</h3>
        <p>{error.message}</p>
      </div>
    );
  }

  if (!products) {
    return (
      <div className="loading">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <div className="products">
      {products.map((product) => (
        <div className="products__product" key={product.id}>
          <Product
            id={product.id}
            name={product.name}
            sku={product.sku}
            price={product.price}
            size={product.size}
            weight={product.weight}
            dimension={product.dimension}
            items={items}
            setItems={setItems}
          />
        </div>
      ))}
    </div>
  );
};

Products.propTypes = {
  items: PropTypes.arrayOf(PropTypes.number).isRequired,
  setItems: PropTypes.func.isRequired,
};

export default Products;
