import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import './App.scss';
import Footer from './components/Footer';
import ProductsPage from './pages/ProductsPage';
import AddProducts from './pages/AddProductsPage';

function App() {
  return (
    <>
      <main>
        <Routes>
          <Route path="/" element={<ProductsPage />} />
          <Route path="/add-product" element={<AddProducts />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
