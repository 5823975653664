import React from 'react';

const DvdDetails = () => (
  <>
    <div className="form-group">
      <label htmlFor="size">
        <span>Size (MB)</span>
        <input type="number" id="size" name="size" required />
      </label>
    </div>
    <p className="details">Please provide DVD Disk size in Megabytes</p>
  </>
);

export default DvdDetails;
