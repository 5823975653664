import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Products from '../components/Products';
import API_BASE_URL from '../api';

const ProductsPage = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  const deleteClickHandler = () => {
    fetch(`${API_BASE_URL}products`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', // Required for CORS support to work

      },
      body: JSON.stringify({ ids: items }),
    })
      .then((response) => response.json())
      .then((data) => {
        setItems([]);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addClickHandler = () => {
    navigate('/add-product');
  };

  return (
    <>
      <Header title="Product List">
        <button type="button" onClick={addClickHandler}>ADD</button>
        <button type="button" onClick={deleteClickHandler} id="delete-product-btn">MASS DELETE</button>
      </Header>
      <Products items={items} setItems={setItems} />
    </>
  );
};

export default ProductsPage;
